import { ref, onMounted } from 'vue';

export default function useApiGatewayUrl() {
    const apiGatewayUrl = ref('');

    onMounted(() => {
        if (process.client) {
            apiGatewayUrl.value = window.location.origin + '/.netlify/functions';
        }
    });

    return { apiGatewayUrl };
}
